import React, { Component } from 'react'
import warranty from "../images/warranty.png"
import ExactPrice from './exact-price';
import SizeChart from './size-chart';
import "./product-description.css";
import { Link } from 'gatsby'

var isOpen = false;
var price = 0;
var isSizeChartOpen = false;
// var imageSrc = "";

class ProductDescription extends Component{
    constructor(props){
        super(props)
        
        this.state = {
            isOpen,
            price,
            isSizeChartOpen,
            // imageSrc : props.data.frontmatter.images[0].image.childImageSharp.fluid,
        }
    }

    componentDidMount(){
       this.setState({
           isOpen : false,
           price : 0,
           isSizeChartOpen : false,
          //  imageSrc : this.props.data.frontmatter.images[0].image.childImageSharp.fluid,
       })
    }

    getExactPrice = (length, width) => {
        var calculatedPrice = getPrice(
          Math.min(length, width),
          Math.max(length, width),
          this.props.data.frontmatter.a,
          this.props.data.frontmatter.b,
          this.props.data.frontmatter.c,
          this.props.data.frontmatter.d)
        this.setState({price : getForamttedPrice(calculatedPrice)});
    }

    handleCalculatorClose = () => {
        this.setState({isOpen : false});
    }

    handleSizeChartClose = () => {
      this.setState({isSizeChartOpen : false});
  }

  handleStyleChange = (image) => {
    // this.setState({imageSrc : image});
  }
    render(){
        var smallestSize = getSmallestSizeOfTheProduct(this.props.data.frontmatter.sizes[0])
        var basePrice = getPrice(
            8,
             smallestSize,
              this.props.data.frontmatter.a,
               this.props.data.frontmatter.b,
               this.props.data.frontmatter.c,
                this.props.data.frontmatter.d)

        return(
          <div>
            <div style={{
                display: this.state.isOpen ? 'block' : 'none'
            }}>

<ExactPrice
 submitValue={this.getExactPrice}
 price={this.state.price}
 handleClose={this.handleCalculatorClose}
 smallestSize={this.props.data.frontmatter.sizes[0]}/>

 </div>

 <div 
 style={{display: this.state.isSizeChartOpen ? 'block' : 'none'}}>

<SizeChart
handleClose={this.handleSizeChartClose}/>

 </div>

            <div className="productDetailTopContainer">
            <img  className="productImage" id="productImage" 
               src={this.props.data.frontmatter.images[0].image != null ? this.props.data.frontmatter.images[0].image.publicURL : ""}
               alt={this.props.data.frontmatter.title}
           />
            <div className="productDetailTopRightContainer">
            <h2 className="designNameText">{this.props.data.frontmatter.title}</h2>

              <div className="productDescriptionContainer">
               <div className="designDescriptionText">{this.props.data.frontmatter.description}</div>
              </div> 
              <div className="productSelectionContainer">
              <AvailableStyles 
              handleClick = {this.handleStyleChange}
              styles={this.props.data.frontmatter.images}/>
              <div className="sizeSelectionContainer">
                <div className="sizeSelectionHeaderContainer">
                <p className="availableText">Available sizes</p>
                <p className="sizeChartButton" id="sizeChartButtonForNormalScreen"onClick={() => this.setState({isSizeChartOpen : true})}>
                  SIZE CHART <i className="primaryRightArrow"></i>
                  </p>
                </div>
           
            <div className="sizesContainer" style={{
              display: 'flex',
            }}>
            
            {this.props.data.frontmatter.sizes.map(((size) => (
         <p className="sizeText">{size}</p>
       )))}
 <p className="sizeChartButton" id="sizeChartButtonForMediumScreen" onClick={() => this.setState({isSizeChartOpen : true})}>
                  SIZE CHART <i className="primaryRightArrow"></i>
                  </p>
            </div>

              </div>
            
            </div>
            <div className="priceContainer">

<div className="priceTextContainer">
<p style={{
  marginRight: '8px',
  marginBottom:'0px',
  fontSize:'0.8em',
}}>Starting from</p>
<p className="productPriceText">Rs {getForamttedPrice(basePrice)}</p>
</div>

<div className="buttonContainer">
<button className="homeButton calculatorButton" id="homeButtonId" onClick={() => this.setState({isOpen : true})}>Cost  Calculator</button>
 <Link className="homeLink" to='/contact-us' className="bookNowTopButton" >
              <button className="homeButton" id="homeButtonId">BOOK  NOW</button>
                </Link>
</div>
</div>
            </div>

            </div>
        
          <div className="productDetailBottomContainer">
            <div className="materialsContainer">
            <p className="featuredComponents">Featured components and description</p>
            
                         <LoadFourItems designItems ={this.props.data.frontmatter.items}/>
                         < Greeting count={this.props.data.frontmatter.items.length}/>
            </div>
            <div className="productDetailBottomRightContainer">
            <Link className="homeLink" to='/contact-us' className="bookNowBottomButton" style={{textDecoration:'none'}}>
              <button className="homeButton bookNowBottomButton" id="homeButtonId">Book Now</button>
                </Link>
                <div className="warrantyContainer">
                      <div className="warrantyImageContainer">
                      <img className="warrantyImage"  src={warranty}/>
                      </div>
            
                      <div className="productContentRightBottom"
                      style={{
                        padding:'30px',
                        paddingTop:'110px',
                        fontFamily:'open sans',
                        fontSize:'16px',
                        fontWeight:'300',
                        background:'#f4f4f8',
                      }}
                      dangerouslySetInnerHTML={{ __html: this.props.data.html }}
                      >
                      </div>
                      </div>
                    </div>
              </div>
          </div>
        )
    }
}

function getSmallestSizeOfTheProduct(size){

    const smallestSizeForSmall = 8;
    const smallestSizeForMedium = 12;
    const smallestSizeForLarge = 15;

  let smallestSize = 0;
  
  if(size == "Small"){
    smallestSize = smallestSizeForSmall;
  }
  else if(size == "Medium"){
    smallestSize = smallestSizeForMedium;
  }
  else{
    smallestSize = smallestSizeForLarge;
  }
return smallestSize;  
  }
  
  function getPrice(smallestSize, largestSize, x, y, xy, c){
    var basePrice = (Number(largestSize) * Number(x)) + (Number(smallestSize) * Number(y)) + (Number(smallestSize) * Number(largestSize) * Number(xy)) + Number(c);
return basePrice;
  }

  class AvailableStyles extends Component{
    render(){
      return(
        <div className="styleSelectionContainer">
  
        <p className="availableText">
        Available styles</p>
      
        <div className="stylesContainer" style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom:'0',
        }}>
        
        {this.props.styles.map(((image, index) => (
    
            <img
            className="styleImage"
            style={{
              height:'30px',
              width:'30px',
              border: index == 0 ? '2px solid #fe5f55' : 'none',
            }}
            onClick={(e) => {
              var styleImage = document.getElementsByClassName("styleImage");
              var productImage = document.getElementById("productImage");
              for(var i = 0; i < styleImage.length; i++)
            {
              styleImage[i].style.border = "none";
            }
            styleImage[index].style.border = "2px solid #fe5f55";
            productImage.src = image.image.publicURL
            // this.props.handleClick(image.image.childImageSharp.fluid);
            }
          }
               src={image.image != null ? image.image.publicURL : ""}/>
    
      )))}
      
        </div>
      
        </div>
      );
    }
  }

  function Greeting(props) {
    if (props.count > 4) {
      return <div
      onClick={(e) => LoadRemainingItems()}
      className="seeMore" style={{
        marginTop: '14px',
        fontFamily: 'open sans',
        fontSize:'16px',
        color:'#fe5f55',
        fontWeight:'600',
        cursor:'pointer'
      }}>{props.count - 4 } more</div>
    }
    return null;
  }
  
  function LoadFourItems(props) {
    return (
      <div>
    {props.designItems.map((item, index) => (
      <div>
      <div className="productItem" style={{display : index < 4 ? 'flex' : 'none'}}>
        <img className="productItemImage"
               src={item.image != null ? item.image.publicURL : ""}
               alt={item.name}
           />
      <div className="productItemTextContent">
        <h5 className="productItemTitle" 
        style={{
          fontFamily: 'open sans',
          fontSize: '16px',
          fontWeight: '500',
          color: '#000000'
        }}>{item.name}</h5>
    
    
        <div className="productItemDecription"
         style={{
          fontFamily: 'open sans',
          fontSize: '16px',
          fontWeight: '300',
          marginTop:'-10px',
          color: '#000000',
          whiteSpace:'pre-line'
        }}>
          <div dangerouslySetInnerHTML={createMarkup(item.materials)} />
          </div>
        
      </div>
    </div>
    </div>
    
     ))}
     </div>
    )
  }
  
  function createMarkup(materials) {
    return {__html: materials};
  }

  function LoadRemainingItems() {
    console.log(0);
    var items = document.getElementsByClassName("productItem");
    var seeMore = document.getElementsByClassName("seeMore");
    for(var i = 0; i < items.length; i++){
      items[i].style.display= "flex";
    }
    seeMore[0].style.display= "none";
  }
  
  function getForamttedPrice(price){
    price=price.toString();
var lastThree = price.substring(price.length-3);
var otherNumbers = price.substring(0,price.length-3);
if(otherNumbers != '')
    lastThree = ',' + lastThree;
var formattedPrice = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
return formattedPrice;
  }
  export default ProductDescription