import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout.js"
import "./product-details.css";
import ProductDescription from "../components/product-description";

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout showLocation = {false}>
      <ProductDescription data = {post}/>
    </Layout>
    )
  }

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        a
        b
        c
        d
        images{
          image{
            childImageSharp{
              fluid{
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            publicURL
          }
        }
        sizes
        items{
          name
          image{
            childImageSharp{
              fluid{
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            publicURL
          }
          materials
        }
      }
    }
  }
`