import React, { Component } from "react";
import "./exact-price.css";
import TextField from '@material-ui/core/TextField';

var length;
var width;
var showPrice;

class ExactPrice extends Component{
      
    constructor(props){
        super(props)
        this.state = {
            length,
            width,
            showPrice,
        }
    }

    componentDidMount(){
        
        this.setState({
            length : 0,
            width : 0,
            showPrice : false,
        })
    }

    handleLengthChange(length){
        console.log(length.target.value);
        this.setState({
            length : length.target.value
        })
    }

    handleWidthChange(width){
        console.log(width.target.value);
        this.setState({
            width : width.target.value
        })
    }

    calculateExactPrice(e){
        e.stopPropagation();
        console.log("smallestSizedasdadadadasdadasdasdada");
        this.props.submitValue(this.state.length, this.state.width);

        var isValidEntry = false;
        if(this.props.smallestSize == "Small"){
          isValidEntry = this.state.length >= 8 && this.state.width >=8

        }
        else if(this.props.smallestSize == "Medium"){
          isValidEntry = (this.state.length >= 8 && this.state.width >=12 ) ||
                              (this.state.width >= 8 && this.state.length >=12 )
        }
        else{
          isValidEntry = (this.state.length >= 8 && this.state.width >=15 ) ||
          (this.state.width >= 8 && this.state.length >=15 )
        }

        console.log("123");
        console.log(isValidEntry);
        if(isValidEntry){
          this.setState({showPrice : true})
          this.setState({showError : false})

        }
        else{
          this.setState({showPrice : false})
          this.setState({showError : true})
        }
    }

    close(e){
        e.stopPropagation();
        this.props.handleClose();
        this.setState({showPrice : false});
        this.setState({showError : false});
    }
    render(){
        return(
<div id="myModal" className="priceCalculatorContainer">

  <div className="priceCalculatorContainer-content">
  <div onClick = {(e) => this.close(e)}>
      <span className="close" >&times;</span>
      </div>
      <div className="priceCalculatorContainer-bottom">
      <h6 className="calculatorDialogInfo">Please enter your room size (in feets) to know the exact price for this room.</h6>
    <div className="lengthWidthTextFieldContainer">
        <div className="textFieldContainer">
            <TextField
        id="standard-name"
        label="Length (ft)"
        type="number"
        onChange={(e) => this.handleLengthChange(e)}
        margin="normal"
      />
        </div>
     
        <div className="textFieldContainer">
            <TextField
        id="standard-name"
        label="Width (ft)"
        type="number"
        onChange={(e) => this.handleWidthChange(e)}
        margin="normal"
      />
        </div>

    </div>
   
    <button onClick={(e) => this.calculateExactPrice(e)} className="homeButton">Calculate</button>
    <p style={{
        display : this.state.showPrice ? 'block' : 'none',
    }}
    className="exactPriceText"
    >Rs {this.props.price}</p>
    <p style={{
        display : this.state.showError ? 'block' : 'none',
    }}
    className="exactPriceErrorText"
    >Sorry this room won't look good in the provided room size. Please refer size chart</p>

    </div>
  </div>
  </div>
        );
    }
}

export default ExactPrice;